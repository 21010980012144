/* DataFilter  */
.scrollBox {
  padding-top: 6px;
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (min-height: 500px) {
  .scrollBox {
    height: 375px;
  }
}

.iconFilter {
  padding-right: 8px;
  padding-bottom: 2px;
  text-align: center;
}

.boxHeader {
  font-size: 18px;
}

.filterAlertBox {
  background-color: #e2002a;
  border-color: #e2002a;
  border: 1px solid;
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 6px 0 6px 0;
  margin-top: 26vh;
  margin-bottom: 26vh;
}

.loading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
