/* Timeline Bargraph Cell Layout */
.durationCell,
.durationCellGrey,
.startCell,
.startCellGrey,
.endCell,
.endCellGrey {
  height: 16px;
  margin-bottom: 4px;
}

.durationCell {
  background-color: #e2002a;
}

.durationCellGrey {
  background-color: #d6d6d6;
}

.startCell {
  background-color: #e2002a;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.startCellGrey {
  background-color: #d6d6d6;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.endCell {
  background-color: #e2002a;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.endCellGrey {
  background-color: #d6d6d6;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.icon {
  font-size: 18px;
  composes: tint from '../css/colors.module.css';
}

.iconGrey {
  margin-top: 4px;
  font-size: 18px;
  color: #333;
}
