@value lightGreyBorder from '../css/colors.module.css';

.leftPanel {
  width: 250px;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.locationList {
  flex: 1;
  border: 1px solid lightGreyBorder;
  overflow-y: auto;
  margin-bottom: 20px;
}

.locationTable {
  composes: table from '../css/tables.module.css';
}

.locationTable > tbody > tr {
  height: 40px;
  vertical-align: middle;
}

.locationTable > tbody > tr > td {
  vertical-align: middle;
}

.radioButtonCol {
  width: 40px;
}

.radioButtonCol > span {
  height: 40px !important;
  width: 40px !important;
}

.locationName {
  padding-left: 8px;
  display: inline-block;
}

.disabledRow {
  color: #959595;
}

.disabledRow:hover {
  background-color: transparent !important;
}
