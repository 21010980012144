.container {
  flex: 1;
  overflow-y: auto;
  padding-top: 2px;
}

.tenantTable {
  composes: table from '../css/tables.module.css';
}

.tenantTable > tbody > tr > td {
  height: 37px;
}
