.container {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
  padding-right: 12px;
  border-bottom: 1px solid #ababab;
}

.searchFields {
  flex: 1;
  display: flex;
  flex-direction: row;
  max-width: 900px;
}

.searchBox {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.incidents {
  padding-top: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.checkbox {
  padding-top: 11px;
  white-space: nowrap;
}

.pickers {
  display: flex;
  flex-direction: row;
}

.pickers > div {
  min-width: 150px;
  margin-right: 12px;
}

.searchBox > div:first-child {
  min-width: 150px;
  margin-right: 12px;
}

.buttons > button {
  margin-right: 12px;
}

@media only screen and (max-width: 991px) {
  .searchFields {
    flex-direction: column;
  }
}

@media only screen and (max-width: 767px) {
  .filter,
  .searchFields,
  .searchBox,
  .pickers {
    flex-direction: column;
  }

  .filter {
    align-items: stretch;
    padding-left: 12px;
  }

  .pickers > div,
  .buttons {
    margin-top: 8px;
    margin-right: 0;
  }
}
