.container {
  flex: 1;
  overflow-y: auto;
  min-width: 0;
  font-size: 14px;
}

.button {
  margin-right: 16px !important;
  margin-top: 12px !important;
}

.closeParticipationsButton {
  flex: 1;
  display: flex;
  max-height: 36px;
  margin-bottom: 12px;
}

.buttonContainer {
  margin-top: 12px;
  margin-left: 24px;
}

.centeredFullscreenSpinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.centeredFullscreenSpinner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  margin-bottom: 12px;
}

.infoAndButtonContainer {
  display: flex;
  margin-top: 20px;
}
