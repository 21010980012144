.incidentRow > td > div {
  height: 20px;
}

.incidentRow > td {
  padding: 5px;
}

.crowdTaskIncidentRow {
  composes: incidentRow;
  border-left: 1px solid #ddd !important;
  border-right: 1px solid #ddd !important;
  height: 40px;
}

.crowdTaskIncidentRow > td {
  border-top: 1px solid #ddd !important;
  border-bottom: 1px solid #ddd !important;
}

.radioButtonCol {
  width: 40px;
  padding: 0 !important;
}

.radioButtonCol > span {
  width: 40px;
  height: 40px;
}

.causeIconCol {
  width: 40px;
}

.messageCol {
  width: 220px;
}

.narrowCol {
  width: 7vw;
}

.dateCol {
  width: 84px;
}

.longDateCol {
  width: 168px;
}

.iconCol {
  width: 34px;
}

.changeIndicatorCol {
  width: 14px;
}

.scrollbarPlaceHolderCol {
  width: 17px;
}

.causeIconBodyCol {
  composes: causeIconCol;
  text-align: center;
  padding-top: 8px !important;
}

.changeIndicator {
  composes: changeIndicatorCol;
  padding: 0;
  vertical-align: middle;
  text-align: center;
}

.chatIconHeaderCol {
  composes: iconCol;
  padding-top: 7px !important;
}

.favoriteIconHeaderCol {
  composes: iconCol;
}

.chatIconBodyCol {
  composes: iconCol;
  padding-top: 7px !important;
}

.favoriteButton {
  text-decoration: none;
}

.favoriteButton:hover {
  text-decoration: none;
}

.favoriteIconBodyCol {
  composes: iconCol;
  padding-top: 7px !important;
}

.causeIcon {
  font-size: 22px;
}

.icon {
  font-size: 24px;
}

.unread {
  font-weight: bold;
}

/* Workaround for issue #1646 */
.invisibleRow > td {
  height: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}
