.tabLabel {
  composes: tintLink from '../css/colors.module.css';
  font-size: 16px;
  font-weight: bold;
  margin-right: 32px;
  margin-bottom: -2px;
  padding-bottom: 7px;
}

.tabLabel,
.tabLabel:focus,
.tabLabel:hover,
.tabLabel:visited {
  text-decoration: none;
}

.tabLabelActive {
  composes: tintBorderBottom from '../css/colors.module.css';
}
