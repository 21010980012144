/* Table */
@value lineHeight: 40px;
@value border: 1px solid #d5d5d5;

.container {
  margin-top: 12px;
}

.headerContainer {
  white-space: nowrap;
}

.tableWrapper {
  white-space: nowrap;
}

.cell {
  display: inline-block;
  height: lineHeight;
  line-height: lineHeight;
}

.headerCell {
  composes: cell;
}

.table {
  display: inline-block;
  white-space: normal;
  border: border;
}

.row {
  height: lineHeight;
  white-space: nowrap;
  cursor: pointer;
}

.headerRow {
  display: inline-block;
  white-space: nowrap;
  height: lineHeight;
  border: border;
  border-bottom: 0;
  background-color: #f0f0f0;
}

.cellContent {
  margin-left: 4px;
  overflow: hidden;
  white-space: nowrap;
}

.text {
  composes: cellContent;
  text-overflow: ellipsis;
}

.headerText {
  composes: text;
  margin-bottom: 10px;
  font-weight: bold;
}

.headerTextIcon {
  composes: text;
  font-weight: bold;
  line-height: 0;
  margin-bottom: 20px;
}

.headerIcon {
  composes: cellContent;
  font-weight: bold;
}

.selectedBackground {
  background-color: #efefef;
}
