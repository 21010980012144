.changeLink:hover {
  cursor: pointer;
  text-decoration: underline;
}

.tenantRow:hover {
  background-color: #fff !important;
}

.tenantRow > td {
  vertical-align: middle !important;
  padding: 5px;
}
