.table {
  background-color: rgba(0, 0, 0, 0);
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.table > thead > tr {
  border-bottom: 1px solid #ddd;
}

.table > thead > tr > th {
  background-color: #fff;
  font-weight: bold;
  font-size: 15px;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table > tbody > tr:hover {
  background-color: #f5f5f5;
}

.table > tbody > tr > td {
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.table > tbody > tr:nth-child(2) > td {
  border-top-width: 0;
}

.tableBodyWrapper {
  flex: 1;
  overflow-y: scroll;
}
