/* Table */
@value lineHeight, border from './TimelineTableBase.module.css';
@value width1: 353px;
@value height1: calc(lineHeight * 4 + 1px);

.table {
  composes: table from './TimelineTableBase.module.css';
  overflow-y: hidden;
  overflow-x: hidden;
  width: width1;
  max-width: width1;
  max-height: height1;
  vertical-align: top;
}

.row {
  composes: row from './TimelineTableBase.module.css';
  width: width1;
  max-width: width1;
  border-bottom: border;
}

.rowSelected {
  composes: row;
  composes: selectedBackground from './TimelineTableBase.module.css';
}

.lastRow {
  composes: row;
  border-bottom: 0;
}

.lastRowSelected {
  composes: row;
  composes: selectedBackground from './TimelineTableBase.module.css';
}

.contentCell {
  composes: cell from './TimelineTableBase.module.css';
  min-width: 50px;
  max-width: 50px;
  width: 50px;
}

.contentCellIcon {
  composes: cell from './TimelineTableBase.module.css';
  min-width: 40px;
  max-width: 40px;
  width: 40px;
}

.contentCellTask {
  composes: cell from './TimelineTableBase.module.css';
  min-width: 165px;
  max-width: 165px;
  width: 165px;
}

.contentCellStatus {
  composes: cell from './TimelineTableBase.module.css';
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}

.emptyCell {
  composes: cell from './TimelineTableBase.module.css';
  min-width: 365px;
  max-width: 365px;
  width: 365px;
  color: #959595;
  cursor: default;
}

.headerRow {
  composes: headerRow from './TimelineTableBase.module.css';
  width: width1;
  max-width: width1;
  vertical-align: bottom;
}

.headerCell {
  composes: headerCell from './TimelineTableBase.module.css';
  min-width: 50px;
  max-width: 50px;
  width: 50px;
}

.headerCellIcon {
  composes: headerCell from './TimelineTableBase.module.css';
  min-width: 40px;
  max-width: 40px;
  width: 40px;
}

.headerCellTask {
  composes: headerCell from './TimelineTableBase.module.css';
  min-width: 165px;
  max-width: 165px;
  width: 165px;
}

.headerCellStatus {
  composes: headerCell from './TimelineTableBase.module.css';
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
