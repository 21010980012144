/* Regions Map Classes */
.regionSelected {
  composes: tintFill from '../css/colors.module.css';
  stroke-miterlimit: 10;
}
.regionUnselected {
  fill: #505050;
  stroke-miterlimit: 10;
}
.regionUnknown {
  fill: #3a3a3a;
  stroke: #505050;
  stroke-miterlimit: 10;
}

.regionText {
  fill: #fff;
  font-family: Arial, sans-serif;
  pointer-events: none;
  font-size: 5px;
}

.regionHoverActive {
  stroke: #fff;
  cursor: pointer;
}

.regionHoverInactive {
  stroke: #909090;
}
