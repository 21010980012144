.app {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  display: flex;
  min-height: 0;
  flex-direction: column;
}
