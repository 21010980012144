.loginPage {
  background: #333;
  background-size: cover;
  font-family: 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 12% 0 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.errorBox {
  width: 100%;
  padding: 4px 0 4px;
  background: #e2002a;
  color: #fff;
  font-weight: bold;
  margin-bottom: 4px;
}

.ccaBackgroundLogo {
  position: absolute;
  top: 48px;
  right: 48px;
  padding: 0;
  margin: 0;
  width: 90px;
}

.textInput:focus {
  box-shadow: none !important;
}

.form {
  text-align: center;
  max-width: 600px;
  margin: 0 auto 0;
  position: relative;
  z-index: 1;
  background: #fff;
}

.formHeader {
  padding: 0 24px 16px;
}

.formHeaderText {
  font-weight: bold;
  padding-top: 18px;
}

.formBody {
  padding: 0 45px 45px;
}

.formBody .textInput {
  font-family: 'Arial', sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}

.loginButton {
  background-color: #e2002a;
  font-family: 'Arial', sans-serif;
  outline: 0;
  width: 30%;
  border: 0;
  padding: 10px 15px 10px;
  margin-top: 10px;
  color: #fff;
  font-size: 14px;
  transition: all 0.3 ease;
  cursor: pointer;
  font-weight: bold;
}

.loginButton:hover {
  opacity: 0.7;
}

.centered {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
