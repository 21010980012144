.input > input[type='checkbox'],
.input > input[type='radio'] {
  display: none;
}

.input > input[type='checkbox'] + label,
.input > input[type='radio'] + label {
  background-color: #3a3a3a;
  position: relative;
  height: 20px;
  width: 20px;
  display: block;
  transition: box-shadow 0.4s, border 0.4s;
  border: solid 1px #505050;
  box-shadow: 0 0 1px #505050;
  cursor: pointer;
  vertical-align: middle;
}

.input > input[type='checkbox'] + label {
  border-radius: 0;
  margin: 4px 0;
}

.input > input[type='radio'] + label {
  border-radius: 25px;
}

.input > input[type='checkbox'] + label:hover,
.input > input[type='checkbox']:checked + label,
.input > input[type='radio'] + label:hover,
.input > input[type='radio']:checked + label {
  border: solid 1px #e2002a;
  box-shadow: 0 0 1px #e2002a;
}

.input > input[type='checkbox']:checked + label::after,
.input > input[type='radio']:checked + label::after {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: 'unite_inform_icon_font' !important;
  height: 1em;
  position: absolute;
  left: 0;
  right: 0;
  color: #e2002a;
  line-height: 1;
  font-size: 14px;
  text-align: center;
  /* this fixes the different height placings of the browsers */
  top: 50%;
  -moz-transform: translateY(-51%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-51%);
}

.input > input[type='checkbox']:checked + label::after {
  content: '\e90f';
}

.input > input[type='radio']:checked + label::after {
  content: '\e924';
}
