.iconFilter {
  padding-right: 8px;
}

.filterHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 2px;
}

.filterText {
  padding-left: 12px;
}

.title {
  composes: white from '../css/colors.module.css';
}
