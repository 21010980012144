.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.form {
  margin-top: 12px;
  margin-right: 20px;
}

.buttonRow {
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 15px;
  justify-content: space-between;
  display: flex;
}

.centeredFullscreenSpinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.centeredFullscreenSpinner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  margin-bottom: 12px;
}

.okButton {
  margin-left: 12px;
}

.errorText {
  composes: errorColor from '../css/colors.module.css';
  font-weight: bold;
}
