.container {
  flex: 1;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.inner {
  padding: 20px;
}

.buttonRow {
  margin-top: 20px;
  justify-content: space-between;
  display: flex;
}

.form {
  margin-top: 30px;
}
