.container {
  flex: 1;
  overflow-x: none;
  overflow-y: auto;
  padding-top: 10px;
  padding-right: 15px;
}

.horizontalContainer {
  display: flex;
}

.info {
  flex: 3;
  display: flex;
  flex-direction: column;
}

.contact {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.field {
  margin-top: 6px;
}

.title {
  composes: grey from '../css/colors.module.css';
}
