.overlay {
  composes: darkBackground from '../css/colors.module.css' !important;
}

.darkBody {
  composes: white from '../css/colors.module.css';
}

.darkRightButton {
  color: #e2002a !important;
}

.darkLeftButton {
  color: #fff !important;
}

.title {
  composes: white from '../css/colors.module.css' !important;
}

@media (min-height: 500px) {
  .darkBody {
    height: 456px;
  }
}
