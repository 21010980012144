@value lightGreyBorder: #c4c4c4;

.chatTextField {
  border-top: 1px solid lightGreyBorder;
  padding-left: 15px;
  padding-right: 28px;
  margin: 0 -15px 0;
}

.chatInput > div > hr {
  display: none;
}

.chatInput {
  padding-right: 8px;
  white-space: normal;
}

.closed {
  padding: 8px;
  margin: 8px 0 8px;
  width: 100%;
}

.textFieldWrapper {
  flex: 1;
  display: flex;
  align-items: center;
}

.buttonWrapper {
  margin-left: 8px;
}
