.navbar {
  min-height: 50px;
  background-color: #3a3a3a;
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.left {
  display: flex;
  align-items: center;
  margin-left: 2px;
}

.left > a,
.left > a:visited,
.left > a:hover {
  text-decoration: none;
  color: white;
}

.right {
  display: flex;
}
