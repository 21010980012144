@import 'inform_icons.css';

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  min-height: 100%;
  font-family: 'Arial', sans-serif;
  overflow: hidden;
  font-size: 16px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fontSmall {
  font-size: 10px;
}

/* relative height overflow:scroll fix */
body,
html,
#root {
  height: 100%;
}

/* IE no big X fix */
::-ms-clear {
  display: none;
}

/* Webkit Scrollbar to ensure same looks and behaviour cross-OS */
::-webkit-scrollbar {
  width: 17px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

/*** OeBB Color Set Classes ***/
/* Background Colors */
.darkBackground {
  background-color: #333;
}
.closedIncidentBackground {
  background-color: #f6f6f6;
}
.tableViewBackground {
  background-color: #efeff4;
}

/* Icon Colors */
.greyIcon {
  background-color: #959595;
}

/* Text Colors */
.greyText {
  color: #959595;
}
.lightGrey {
  color: #c4c4c4;
}
.whiteText {
  color: #fff;
}
.blackText {
  color: #000;
}
.standardText {
  color: inherit;
}

/* Other Colors */
.closedIncident {
  color: #bbb;
}
.border {
  border-color: #ababab;
}
.separator {
  color: #d6d6d6;
}

/* tintColor as color for hyperlinks */
.redLink,
.redLink:link,
.redLink:visited,
.redLink:hover,
.redLink:active {
  color: #e2002a;
}

h4 > a,
h4 > a:hover {
  color: #e2002a;
}

/* Highlighted text */
.highlight {
  background-color: #ff0;
}

/* standard hr border-color */
hr {
  border-top-color: #ababab;
}

td,
th {
  padding: 0;
}

th {
  text-align: left;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

.outlineRed {
  border-color: #e2002a;
  color: #e2002a;
}

.outlineRed,
.outlineRed:focus,
.outlineRed:active {
  background: none;
  font-size: 16px;
  padding-left: 24px;
  padding-right: 24px;
  outline: 0 none !important;
}

.outlineRed:hover {
  color: #fff !important;
  background-color: #e2002a;
  outline: 0 none !important;
}

/* Alignment helpers */
.alignLeft {
  float: left;
}

.alignRight {
  float: right;
  text-align: right;
}

/* Alignment fix */
.clearBoth {
  clear: both;
}

/* Convenient setting of cursors */
.pointerCursor {
  cursor: pointer;
}

.defaultCursor {
  cursor: default;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  user-select: none;
}

.labelText {
  font-size: 14px;
  padding-left: 25px;
  width: 288px;
  display: block;
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}

.spacer {
  margin: 0 4px 0;
}

.icon-check.validationSuccessful {
  color: green;
}

/* IncidentRow */
td > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  vertical-align: middle;
  margin-top: 5px;
  font-size: 14px;
}
