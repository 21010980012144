.messageWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.ownWrapper {
  composes: messageWrapper;
  justify-content: flex-end;
}

.message {
  border-radius: 10px;
  margin: 0 8px 8px 0;
  padding: 8px;
  overflow: hidden;
  text-align: left;
  width: 80%;
}

.messageBody {
  white-space: pre-wrap;
  word-break: break-all;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.user {
  font-weight: bold;
}

.timestamp {
  float: right;
}

.ownMessage {
  composes: message;
  background-color: #0084ff;
  color: white;
  align-self: flex-end;
}

.pendingMessage {
  composes: ownMessage;
  opacity: 0.5;
}

.otherMessage {
  composes: message;
  background-color: #f0f0f0;
}

.blockedMessage {
  background-color: #fafafa;
  color: #959595;
}

.blockTemplate {
  line-height: 24px;
  display: flex;
}

.block {
  composes: blockTemplate;
  cursor: pointer;
  color: #e2002a;
}

.blocked {
  composes: blockTemplate;
  color: #959595;
}

.block:hover {
  opacity: 0.5;
}

.blockIcon {
  padding-right: 8px;
  font-size: 24px;
}

.userInfo {
  display: flex;
  flex-wrap: wrap;
  text-align: right;
}

.phoneNumberLink {
  flex: 1;
}

.phoneNumberLink,
.phoneNumberLink:visited,
.phoneNumberLink:focus,
.phoneNumberLink:hover {
  color: inherit;
  text-decoration: underline;
}
