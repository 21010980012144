.incidentList {
  max-height: 1000px;
}

.rightPanel {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
}

.upperContainer {
  flex: 1;
  overflow-y: auto;
}

.form {
  margin-top: 4px;
}

.buttonRow {
  margin-top: 20px;
  justify-content: space-between;
  display: flex;
}
