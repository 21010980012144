.mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.tableMinWidth {
  min-width: 610px; /* IE does not shrink properly below that otherwise */
}
