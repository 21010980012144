.incidentHeaderRow > td {
  padding: 5px;
}

.noPadding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: 0 none !important;
}

.outlineSmall {
  font-weight: normal;
  background: none;
  font-size: 14px;
  border: none;
  padding: 3px 3px 0 3px;
  margin: 7px 0 4px 0;
  border-radius: 0;
}

.outlineRedSmall {
  composes: outlineSmall;
}

.outlineGreySmall {
  composes: outlineSmall;
}

.outlineGreySmall:hover {
  color: #959595 !important;
  background: none !important;
  border-bottom: #959595 solid 1px;
  outline: 0 none !important;
}

.outlineRedSmall:hover {
  color: #e2002a !important;
  background: none !important;
  border-bottom: #e2002a solid 1px;
  outline: 0 none !important;
}

.noTextDecoration {
  text-decoration: none !important;
}

.causeWithPagination {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.causeWithPagination > div:nth-child(2) {
  margin-left: 24px;
}
