@font-face {
  font-family: 'unite_inform_icon_font';
  src: url('fonts/unite_inform_icon_font.eot?p41eh4');
  src: url('fonts/unite_inform_icon_font.eot?p41eh4#iefix') format('embedded-opentype'),
    url('fonts/unite_inform_icon_font.ttf?p41eh4') format('truetype'),
    url('fonts/unite_inform_icon_font.woff?p41eh4') format('woff'),
    url('fonts/unite_inform_icon_font.svg?p41eh4#unite_inform_icon_font') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'unite_inform_icon_font' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ID:before {
  content: '\e913';
}
.icon-accept:before {
  content: '\e934';
}
.icon-accident:before {
  content: '\e900';
}
.icon-arrow_back:before {
  content: '\e90d';
}
.icon-arrow_right:before {
  content: '\e90e';
}
.icon-bell:before {
  content: '\e937';
}
.icon-blocked:before {
  content: '\e938';
}
.icon-blue_light:before {
  content: '\e901';
}
.icon-call_out:before {
  content: '\e936';
}
.icon-caret_down:before {
  content: '\e933';
}
.icon-chat:before {
  content: '\e92a';
}
.icon-check:before {
  content: '\e90f';
}
.icon-construction:before {
  content: '\e902';
}
.icon-deviation:before {
  content: '\e903';
}
.icon-disruption:before {
  content: '\e904';
}
.icon-dot:before {
  content: '\e924';
}
.icon-drawer:before {
  content: '\e910';
}
.icon-ended:before {
  content: '\e911';
}
.icon-exit:before {
  content: '\e92e';
}
.icon-faq:before {
  content: '\e93a';
}
.icon-filter:before {
  content: '\e912';
}
.icon-info:before {
  content: '\e928';
}
.icon-info_nofill:before {
  content: '\e939';
}
.icon-line:before {
  content: '\e914';
}
.icon-location_from:before {
  content: '\e915';
}
.icon-location_ft:before {
  content: '\e916';
}
.icon-location_to:before {
  content: '\e917';
}
.icon-media_pause:before {
  content: '\ea2c';
}
.icon-media_play:before {
  content: '\e93c';
}
.icon-media_stop:before {
  content: '\e948';
}
.icon-message:before {
  content: '\e918';
}
.icon-message_i:before {
  content: '\e92f';
}
.icon-misc:before {
  content: '\e905';
}
.icon-neighbouring_country:before {
  content: '\e906';
}
.icon-neighbouring_country_lux:before {
  content: '\e925';
}
.icon-notify:before {
  content: '\e919';
}
.icon-operation:before {
  content: '\e931';
}
.icon-phone:before {
  content: '\e930';
}
.icon-pin:before {
  content: '\e91a';
}
.icon-refresh:before {
  content: '\e91b';
}
.icon-region:before {
  content: '\e91c';
}
.icon-reject:before {
  content: '\e935';
}
.icon-replace:before {
  content: '\e929';
}
.icon-search:before {
  content: '\e923';
}
.icon-settings:before {
  content: '\e91d';
}
.icon-share-ios:before {
  content: '\e92b';
}
.icon-share_android:before {
  content: '\e92c';
}
.icon-sound:before {
  content: '\e91e';
}
.icon-star:before {
  content: '\e920';
}
.icon-star_outline:before {
  content: '\e91f';
}
.icon-strike:before {
  content: '\e907';
}
.icon-support:before {
  content: '\e90b';
}
.icon-tag:before {
  content: '\e92d';
}
.icon-team:before {
  content: '\e921';
}
.icon-technical_error:before {
  content: '\e908';
}
.icon-technical_error_track:before {
  content: '\e926';
}
.icon-technical_error_train:before {
  content: '\e927';
}
.icon-traffic_accident:before {
  content: '\e909';
}
.icon-unknown:before {
  content: '\e90a';
}
.icon-user:before {
  content: '\e922';
}
.icon-vandalism:before {
  content: '\e90c';
}
.icon-weather:before {
  content: '\e932';
}
