.incidentDetailRight {
  padding-right: 20px;
}

.incidentDetailRight * {
  margin-bottom: 0;
  line-height: 1;
}

.incidentDetailRight h5 {
  padding-bottom: 4px;
  color: #959595;
  font-size: 14px;
  line-height: 1em;
  font-weight: bold;
}

.incidentDetailRight h5:first-child {
  margin-top: 19px;
}

.incidentDetailRight p {
  margin-top: 0;
  line-height: 1.1rem;
  white-space: pre-line;
  word-break: normal;
}
