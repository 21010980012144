.table {
  border-collapse: separate;
  border-spacing: 20px 0;
}

.textAlignRight {
  text-align: right;
}

.statisticsScrollBox {
  display: flex;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.statistics {
  display: flex;
  flex: 1;
  flex-basis: 100px;
  min-height: 0;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

.centerText {
  text-align: center;
}

.fetchedAt {
  composes: centerText;
  margin-bottom: 10px;
}
