.incidents {
  flex: 1;
  display: flex;
  min-height: 0;
  padding-top: 2px;
  overflow-x: auto;
}

.incidentList {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Incidents */
.filterBar {
  background-color: #333;
  padding-left: 0;
  padding-right: 0;
  width: 320px;
  overflow-y: auto;
}
