@value menuPadding: 15px;

.menu {
  background-color: white;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #333;
}

.header {
  padding: 7px menuPadding;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #e7e7e7;
}

.content {
  padding: menuPadding menuPadding 5px menuPadding;
  border-bottom: 1px solid #e7e7e7;
}

.version {
  display: flex;
  justify-content: flex-end;
  color: #959595;
}

.footer {
  padding: 0 menuPadding;
}

.underline {
  text-decoration: underline;
}

.left a {
  color: white;
  text-decoration: none;
}

.hotlineLink {
  text-decoration: underline;
}

.menuLink {
  font-size: 18px;
  text-decoration: underline;
}

.menuItem {
  padding: 4px 0;
}

.problems {
  padding: 8px 0;
}
