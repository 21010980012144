@value lightGreyBorder from '../css/colors.module.css';

.list {
  flex: 1;
  direction: rtl;
  overflow-x: none;
  overflow-y: auto;
  margin-right: -1px;
  border-bottom: 1px solid lightGreyBorder;
}

.listHeader {
  padding: 15px;
  font-size: 16px;
  font-weight: bold;
}

.listContent {
  direction: ltr;
  padding-bottom: 10px;
}
