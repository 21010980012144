.container {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
}

.button {
  margin-bottom: 12px !important;
  height: 36px !important;
}

.smallButton {
  composes: button;
  width: 120px;
}

.bigButton {
  composes: button;
  width: 220px;
}
