.selectField {
  vertical-align: bottom;
}

.grid {
  display: flex;
  flex-direction: row;
  width: 90vw;
  max-width: 750px;
}

.gridElement {
  flex: 1;
}

.gridElement:first-child {
  margin-right: 48px;
}

input,
textarea {
  box-shadow: none !important;
}
