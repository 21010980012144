.chatWindow {
  /* The "auto" is necessary for IE to render the window correctly in the incident detail view. */
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 5px;
}

.chatDay {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.chatDayTitle {
  font-weight: bold;
  color: #959595;
  padding: 10px 0;
}
