@value greyText: #959595;

.menuButton {
  margin: 1px 0;
  padding: 0 15px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.menuButton:hover {
  background-color: #080808;
  cursor: pointer;
}

.menuButtonOpen {
  composes: menuButton;
  background-color: #080808;
}

.burgerIcon {
  font-size: 25px;
  margin-top: 4px;
}

.userInfo {
  display: flex;
  align-items: center;
}

.tenantAndUserName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  line-height: 120%;
  margin-bottom: 3px;
}

.tenantName {
  color: greyText;
  font-size: 13px;
}

.userName {
  font-size: 19px;
  white-space: nowrap;
}

.userIcon {
  color: greyText;
  font-size: 35px;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 4px;
}
