.errorScreen {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.errorScreen > table > tbody > tr > td {
  padding: 5px 10px;
}

.errorScreenText {
  font-size: 1.2em;
  text-align: center;
  padding: 0 16px 32px 16px;
}

.title {
  font-size: 1.6em;
  font-weight: bold;
  margin-bottom: 16px;
}

.buttonRow {
  display: flex;
}

.buttonColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
