.table {
  composes: table from '../css/tables.module.css';
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  margin: 6px 0 12px 0;
}

.incidentDetails {
  display: flex;
}

.incidentDetailLeft {
  flex: 0.5;
}

.incidentDetailRight {
  flex: 0.3;
}
