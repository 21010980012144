.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  text-align: center;
  width: 40px;
  height: 40px;
  font-size: 30px;
  color: #e2002a;
  cursor: pointer;
}

.buttonEnabled {
  composes: button;
}

.buttonActive {
  composes: button;
  color: #fff;
  background-color: #e2002a;
}

.buttonDisabled {
  composes: button;
  color: #959595;
  cursor: default;
}

.buttonEnabled:hover {
  color: #fff;
  background-color: #e2002a;
}
