/* stylelint-disable declaration-block-no-redundant-longhand-properties */

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.active {
  padding: 13px 15px;
  background-color: #999;
  border-top-style: solid;
  border-top-color: #999;
  border-top-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #e2002a;
  border-bottom-width: 3px;
}
