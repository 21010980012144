.addTenantRow {
  cursor: pointer;
  text-align: center;
}

.addTenantRow:hover {
  background-color: #f0f0f0;
}

.tenantError {
  color: red;
}

.createTenantRow:hover {
  background-color: #fff !important;
}

.createTenantRow > td {
  padding: 5px;
  vertical-align: middle !important;
}
