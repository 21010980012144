.container {
  margin-top: 30px;
}

.table {
  border-collapse: collapse;
  border: 1px solid #d5d5d5;
  margin-top: 16px;
}

.table tr {
  height: 40px;
  border-top: 1px solid #d3d3d3;
}

.tableHeaderRow {
  background-color: #f0f0f0;
}

.table th,
.table td {
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: content-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.noParticipations {
  color: #959595;
}

.iconCell {
  width: 29px;
  padding-right: 0 !important;
}

.textCell1Button {
  min-width: calc((100vw - 742px) / 100 * 33);
  max-width: calc((100vw - 742px) / 100 * 33);
}
.textCell2Buttons {
  min-width: calc((100vw - 838px) / 100 * 33);
  max-width: calc((100vw - 838px) / 100 * 33);
}
.textCellNoButtons {
  min-width: calc((100vw - 646px) / 100 * 33);
  max-width: calc((100vw - 646px) / 100 * 33);
}

.confirmationHeader1Button {
  min-width: 72px;
  max-width: 72px;
}
.confirmationHeader2Buttons {
  min-width: 168px;
  max-width: 168px;
}
.emptyHeader {
  min-width: 0;
  max-width: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.statusCell {
  min-width: 88px;
  max-width: 88px;
}

.phoneCallCell {
  min-width: 88px;
  max-width: 88px;
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.actionButton {
  margin: 3px 0;
}

.phoneButton {
  composes: actionButton;
  background-color: #08f !important;
}

.phoneButton:hover {
  background-color: #00539c !important;
}

.confirmationButton {
  composes: actionButton;
  background-color: #009e0f !important;
}

.confirmationButton:hover {
  background-color: #00700b !important;
}

.confirmationButtonCell {
  min-width: 88px;
  max-width: 88px;
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.noConfirmationButtonCell {
  min-width: 0;
  max-width: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.infoIcon {
  font-size: 24px;
  color: #e2002a;
  cursor: pointer;
}

.userIcon {
  font-size: 24px;
  color: #c4c4c4;
}

.actionIcon {
  font-size: 20px;
  color: white;
}

.phoneActionIcon {
  font-size: 16px;
  color: white;
}

.participationDropDown {
  font-size: 14px !important;
  height: 40px !important;
  max-height: 40px !important;
}

.participationDropDown > div > div {
  height: 27px !important;
  padding: 12px 32px 1px 0 !important;
}

.participationDropDown > div > svg {
  fill: #000;
}

.itemRow {
  display: flex;
}

.itemRowKey {
  color: grey from '../../css/colors.module.css';
  flex: 1;
}

.itemRowValue {
  color: black;
  flex: 3;
}
