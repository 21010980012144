/* Table */
@value lineHeight, border from './TimelineTableBase.module.css';
@value width2: calc(100vw - 642px);
@value height2: calc(lineHeight * 4 + 18px);
@value timeWidth: 84px;
@value timePartWidth: calc(timeWidth / 12);

.table {
  composes: table from './TimelineTableBase.module.css';
  overflow-y: scroll;
  overflow-x: scroll;
  max-width: width2;
  border: 0;
  border-top: border;
}

.hsh14px {
  composes: table;
  height: calc(lineHeight * 4 + 14px);
  max-height: calc(lineHeight * 4 + 14px);
}

.hsh15px {
  composes: table;
  height: calc(lineHeight * 4 + 15px);
  max-height: calc(lineHeight * 4 + 15px);
}

.hsh16px {
  composes: table;
  height: calc(lineHeight * 4 + 16px);
  max-height: calc(lineHeight * 4 + 16px);
}

.hsh17px {
  composes: table;
  height: calc(lineHeight * 4 + 17px);
  max-height: calc(lineHeight * 4 + 17px);
}

.hsh18px {
  composes: table;
  height: calc(lineHeight * 4 + 18px);
  max-height: calc(lineHeight * 4 + 18px);
}

.row {
  composes: row from './TimelineTableBase.module.css';
  max-width: width2;
  border-bottom: border;
}

.emptyRow {
  composes: row;
  cursor: default;
}

.contentCell {
  composes: cell from './TimelineTableBase.module.css';
  min-width: timeWidth;
  max-width: timeWidth;
  width: timeWidth;
}

.emptyCell {
  composes: contentCell;
  border-bottom: border;
}

.emptyCellSelected {
  composes: emptyCell;
  composes: selectedBackground from './TimelineTableBase.module.css';
}

.headerCell {
  composes: headerCell from './TimelineTableBase.module.css';
  min-width: timeWidth;
  max-width: timeWidth;
  width: timeWidth;
  text-align: center;
  border-right: border;
}

.lastHeaderCell {
  composes: headerCell;
  border-right: 0;
}

.headerRow {
  composes: headerRow from './TimelineTableBase.module.css';
  border-left: 0;
  max-width: calc(width2 - 18px);
  overflow-x: hidden;
  overflow-y: hidden;
  vertical-align: bottom;
}

.timelinePartWrapper {
  vertical-align: top;
  display: inline-block;
  line-height: lineHeight;
  border-bottom: border;
  height: lineHeight;
  max-height: lineHeight;
}

.timelinePartWrapperSelected {
  composes: timelinePartWrapper;
  composes: selectedBackground from './TimelineTableBase.module.css';
}

.timelinePart {
  margin-top: 12px;
  margin-bottom: -12px;
  display: inline-block;
  min-width: timePartWidth;
  max-width: timePartWidth;
  line-height: lineHeight;
}
