.loadingScreen {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  composes: darkBackground from '../css/colors.module.css';
}

.loadingText {
  width: 300px;
  text-align: center;
  color: white;
  margin-bottom: 10px;
}
