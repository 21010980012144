/* FilterSidebar */
.searchBar {
  color: #959595;
  border-color: #ababab;
  background-color: #3a3a3a;
  border-radius: 0;
}

.searchBarButton {
  background-color: #3a3a3a;
}

.searchBarButton > div {
  padding-left: 8px;
  color: #959595 !important;
}

.innerButton {
  background-color: transparent;
  border-radius: 0;
  border-left: none;
  height: 34px;
  width: 34px;
  color: #959595;
  border-color: #ababab;
}

.filterBottom {
  padding-left: 12px;
  padding-right: 39px;
}

.filterHeading {
  font-size: 16px;
  font-weight: normal;
  color: #fff;
}

.filterHeadingSmall {
  composes: filterHeading;
  margin-bottom: 8px;
}

.indentedFilterItem {
  margin-left: 30px;
}

.noOverflowX {
  overflow-x: hidden;
}

.iconButton {
  color: #959595 !important;
  font-size: 1.2rem !important;
}
