.selectField {
  vertical-align: bottom;
  min-width: calc(100vw - 285px);
  max-width: calc(100vw - 285px);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .selectField {
    vertical-align: bottom;
    min-width: calc(100vw - 302px);
    max-width: calc(100vw - 302px);
  }
}

.form {
  display: flex;
  flex-direction: row;
}

.fieldsWrapper {
  flex: 1;
}

.fieldsWrapper > div {
  margin: 8px 0;
}

input {
  box-shadow: none !important;
}

.firstRow {
  display: flex;
  flex-wrap: wrap;
}

.fieldMarginRight {
  margin-right: 8px;
}

.toDateWrapper {
  vertical-align: bottom;
  display: flex;
}

.toDate {
  margin-bottom: 7px;
  margin-right: 8px;
  font-size: 1em;
  display: flex;
  align-self: flex-end;
}

.toDateDisabled {
  composes: toDate;
  color: #b2b2b2;
}
