.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.preserveLinebreaks {
  white-space: pre-line;
}
