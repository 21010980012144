.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
}

.icon {
  composes: lightGrey from '../css/colors.module.css';
  font-size: 100px;
  text-align: center;
}

.title {
  composes: grey from '../css/colors.module.css';
  composes: bold from '../css/text.module.css';
  text-align: center;
  max-width: 100%;
}

.message {
  composes: grey from '../css/colors.module.css';
  font-size: 15px;
  white-space: pre-line;
  text-align: center;
  max-width: 100%;
}

.childrenContainer {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}
