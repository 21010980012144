.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90px;
  border-style: solid;
  border-top-width: 1px;
  border-top-color: #c4c4c4;
  border-right-width: 0;
  border-bottom-width: 1px;
  border-bottom-color: #c4c4c4;
  margin-top: -1px;
  padding: 8px;
  cursor: pointer;
}

.cardUnselected {
  composes: card;
  composes: tintBorderLeftHover from '../css/colors.module.css';
  border-left-width: 1px;
  margin-left: 16px;
  border-left-color: #c4c4c4;
}

.cardUnselected:hover {
  margin-left: 14px;
}

.cardSelected {
  composes: card;
  composes: tintBorderLeft from '../css/colors.module.css';
  background: white;
  margin-left: 13px;
  margin-right: 0;
  box-shadow: -3px 0 4px #c4c4c4;
}

.top {
  display: flex;
  flex-direction: row;
}

.locationName {
  flex: 1;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.locationNameSelected {
  composes: locationName;
  font-weight: bold;
}

.text {
  composes: grey from '../css/colors.module.css';
}

.icons {
  margin-top: -1px;
  font-size: 18px;
  composes: tint from '../css/colors.module.css';
}

.icons > i {
  margin-left: 8px;
}

.bottom {
  display: flex;
  align-items: baseline;
}

.pill {
  border-radius: 12px;
  padding: 1px 8px 1px 8px;
  min-width: 70px;
  margin-right: 4px;
  text-align: center;
  color: white;
}

.tintPill {
  composes: pill;
  composes: tintBackground from '../css/colors.module.css';
}

.greyPill {
  composes: pill;
  composes: greyBackground from '../css/colors.module.css';
}

.runningSince {
  flex: 1;
}

.tooltip {
  background-color: black;
  color: white;
  padding: 6px;
}

.iconSpacer {
  margin-right: 6px;
  display: inline-block;
}
