.iconDetail {
  font-size: 20px !important;
  margin-top: 8px;
}

.row {
  margin-left: 32px;
  padding-top: 10px;
}

.iconRow {
  composes: row;
  padding-bottom: 6px;
}

.noIconRow {
  composes: row;
  padding-bottom: 10px;
}

.detailRowData {
  text-align: right;
  white-space: pre-line;
  word-break: normal;
}
