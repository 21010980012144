.chatTextField {
  border-top: 1px solid #c4c4c4;
  padding-left: 15px;
  padding-right: 28px;
  margin: 0 -15px 0;
  display: flex;
  min-height: 0;
  flex-direction: row;
  align-items: center;
}

.chatView {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.chatWindow {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 5px;
}

.chatInput > div > hr {
  display: none;
}

.chatInput {
  padding-right: 8px;
}

.noWritePermission {
  color: #959595;
}
