/* IncidentDetail */
.incidentDetail [class^='col-'],
.incidentDetail [class*=' col-'] {
  padding: 0;
}

.incidentDetailRow {
  height: 0 !important;
}

/* Keep expanded IncidentDetail from becoming grey on hover */
.incidentDetailRow:hover {
  background-color: transparent !important;
}

.openFeedback {
  float: right;
}

.openFeedback > button {
  margin-right: 14px;
}

.buttonIcon {
  composes: white from '../css/colors.module.css';
}

.flexRow {
  display: flex;
  flex-flow: row wrap;
}

.col {
  flex: 1 1;
  overflow-x: hidden;
}

.col1 {
  composes: col;
}

.col2 {
  composes: col;
}

.col2Big {
  composes: col;
  flex: 2;
}

.colBreak {
  flex-basis: 100%;
  order: 3;
  display: none;
}

.col3 {
  composes: col;
  padding-left: 38px;
}

@media (max-width: 991px) {
  .col {
    flex-basis: 50%;
  }
  .col2 {
    padding-left: 38px;
    padding-bottom: 10px;
    order: 4;
  }
  .colBreak {
    display: block;
  }
  .col3 {
    padding-left: 38px;
    order: 2;
  }
}

@media (max-width: 767px) {
  .flexRow {
    flex-direction: column;
  }
  .col2 {
    order: 2;
  }
  .col3 {
    order: 3;
  }
}
