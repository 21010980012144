.container {
  flex: 1;
  display: flex;
  min-height: 0;
  flex-direction: column;
  padding: 15px 0 0 15px;
}

.header {
  display: flex;
  justify-content: space-between;
  height: 34px;
  padding-right: 15px;
}

.title {
  font-size: 16px;
  font-weight: bold;
}

.contactPerson {
  text-align: right;
  line-height: 1.25;
}

.contactPerson a {
  color: inherit;
  text-decoration: underline;
}
