.linkButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.linkButton:hover,
.linkButton:focus {
  text-decoration: none;
  outline: none;
}
