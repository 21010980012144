.list {
  list-style-type: none;
  line-height: 1;
  padding-left: 7px;
  padding-right: 20px;
  padding-top: 10px;
}

.list hr {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 32px;
  border-bottom-width: 0;
  border-top: 1px solid #ababab;
  /* Hairline width hack */
  transform: scaleY(0.5);
}
