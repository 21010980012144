.chatIcon {
  font-size: 24px;
}

.tintChatIcon {
  composes: chatIcon;
  composes: tint from '../css/colors.module.css';
}

.greyChatIcon {
  composes: chatIcon;
  composes: grey from '../css/colors.module.css';
}

.highlight {
  composes: highlight from '../css/colors.module.css';
}
