.infoContainer {
  display: flex;
  flex: 1;
  padding-top: 10px;
  padding-right: 15px;
}

.col1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 24px;
}

.col2 {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.field {
  margin-top: 6px;
  margin-bottom: 6px;
}

.label {
  composes: grey from '../css/colors.module.css';
}
