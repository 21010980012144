.panel {
  background-color: transparent !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  box-shadow: unset !important;
  border-bottom: #ababab 1px solid !important;
}

.panel::before {
  background-color: transparent !important;
}

.panelHeading {
  padding-left: 12px !important;
  padding-right: 12px !important;
  min-height: 48px !important;
}

.panelHeading > div:first-child {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.panelHeading > div:last-child {
  right: 0 !important;
}

.panelBody {
  padding: 12px !important;
  padding-top: 0 !important;
  border-color: #ababab !important;
}

.caretFilter {
  font-size: 0.9rem;
  composes: white from '../css/colors.module.css';
}
