@value lightGreyBorder from '../css/colors.module.css';

.container {
  flex: 1;
  display: flex;
  min-height: 0;
}

.leftPanel {
  flex: 0 0 auto;
  width: 250px;
  border-right: 1px solid lightGreyBorder;
  display: flex;
  min-height: 0;
  flex-direction: column;
  box-shadow: inset -3px 0 4px #c4c4c4;
}

.buttons {
  padding: 9px 15px;
}

.centeredFullscreenSpinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.centeredFullscreenSpinner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  margin-bottom: 12px;
}
