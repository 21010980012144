.tabBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
}

.tabs {
  display: flex;
}

.tabLabel {
  composes: tintColor from '../css/colors.module.css';
  text-decoration: none !important;
}

.tabLabel > button span {
  font-weight: bold !important;
  font-size: 0.95rem !important;
}

.tabLabelSelected {
  composes: tintBorderColor from '../css/colors.module.css';
  composes: tabLabel;
  border-bottom: 3px solid;
}

.tabLabel > button,
.tabLabelSelected > button {
  opacity: 1 !important;
  padding-bottom: 3px !important;
}

.paper {
  border-right: transparent;
  border-left: transparent;
}
