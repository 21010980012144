/* Hack for IE to size map to the width & height of the parent */
.svgRegionMapContainer {
  position: relative;
  height: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 50%;
}

.svgRegionMap {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
